import React from "react";
import {DynamicPageItemFaqListScheme} from "../../../../types/page.interface";
import styles from './DynamicPageFaqList.module.css';
import DynamicPageItem from "../../DynamicPageItem";

interface Props {
    item: DynamicPageItemFaqListScheme;
}

const DynamicPageFaqList: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.wrapper}>
            {item.children.map((child) => <DynamicPageItem item={child}/>)}
        </div>
    )
}

export default DynamicPageFaqList;