import React from "react";
import {DynamicPageItemImageScheme} from "../../../../types/page.interface";

interface Props {
    item: DynamicPageItemImageScheme;
}

const DynamicPageImage: React.FC<Props> = ({item}) => {
    const style: React.CSSProperties = {};

    if (item.isHidden) {
        return <></>
    }

    if (item.margin) {
        style.margin = item.margin;
    }

    return <img src={item.src} style={style}/>
}

export default DynamicPageImage;