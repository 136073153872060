import React, { useState } from "react";
import {DynamicPageItemFaqListItemScheme} from "../../../../types/page.interface";
import styles from "./DynamicPageFaqListItem.module.css";

interface Props {
    item: DynamicPageItemFaqListItemScheme;
}

const DynamicPageFaqListItem: React.FC<Props> = ({item}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title} onClick={handleOpen}>
                <div>{item.title}</div>
                <img src={isOpen ? "/icons/arrow-up-blue.svg" : "/icons/arrow-down-blue.svg"} alt="ArrowDown"/>
            </div>
            {isOpen && (<div className={styles.text}>{item.text}</div>)}
        </div>
    )
}

export default DynamicPageFaqListItem;