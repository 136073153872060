import NoEscape from "./NoEscape";
import React, {ReactElement} from "react";

interface Props {
    tx: string;
    noEscape?: boolean;
    className?: string;
    style?: React.CSSProperties
}

const Text: React.FC<Props> = ({tx, noEscape, className, style}) => {

    let output: string | ReactElement = tx;

    if (noEscape) {
        output = <NoEscape>{output}</NoEscape>
    }

    return <div className={className} style={style}>{output}</div>;
}

export default Text
