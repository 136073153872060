import React, {useMemo, useState} from "react";
import styles from "./PromoCode.module.css";
import Text from "../Text";

interface Props {
    title: string;
    code: string;
    dark?: boolean;
}

const PromoCode: React.FC<Props> = ({title, code, dark}) => {
    const [isCopied, setCopied] = useState(false);

    const scissorsIcon = useMemo(() => {
        if (dark) {
            return '/icons/scissors-blue.svg'
        }
        return '/icons/scissors-white.svg'
    }, [dark])

    const handleCopyToClipBoard = () => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500)
        // @ts-ignore
        gtag('event', 'promo_code', {
            code
        });
    }

    return (
        <div className={[styles.wrapper, dark ? styles.dark : ''].join(" ")} onClick={handleCopyToClipBoard}>
            <Text tx={title} className={styles.title} noEscape/>
            <div className={styles.code}>{code}</div>
            <div className={styles.scissors}>
                <img src={scissorsIcon} alt='scissors white'/>
            </div>
            {isCopied && (
                <div className={styles.copied}>Zkopírováno</div>
            )}
        </div>
    )
}

export default PromoCode;