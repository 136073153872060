import React from 'react';
import styles from './Logotype.module.css';
import DynamicDownloadAppButton from "../../DynamicPage/Items/DownloadApp/DynamicDownloadAppButton";
import {PageItemType} from "../../../enums/pageItemType.enum";

interface Props {
    claim: string;
    dark?: boolean;
    showDownloadAppButton?: boolean;
}

const Logotype: React.FC<Props> = ({claim, dark, showDownloadAppButton}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.logoTypeWrapper}>
                <img className={styles.image} src='/images/logotype.svg' alt='MEDDI app'/>
                <div className={[styles.text, dark ? styles.dark : ''].join(" ")}>
                    <div className={styles.title}>
                        MEDDI app
                    </div>
                    <div className={styles.claim}>{claim}</div>
                </div>
            </div>
            {showDownloadAppButton && (
                <div>
                    <DynamicDownloadAppButton item={{
                        type: PageItemType.DOWNLOAD_APP_BUTTON,
                        title: "Stáhnout aplikaci ZDARMA",
                        href: "https://onelink.to/meddiapp",
                        code: "MEDDILETO20241",
                        icon: '/icons/cloud-arrow.svg',
                        forceModal: true
                    }}/>
                </div>
            )}
        </div>
    )
}

export default Logotype;