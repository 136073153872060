import React from "react";
import { DynamicPageItemScheme } from "../../types/page.interface";
import {PageItemType} from "../../enums/pageItemType.enum";
import DynamicPageTitle from "./Items/Title/DynamicPageTitle";
import DynamicPageText from "./Items/Text/DynamicPageText";
import DynamicPageContainer from "./Items/Container/DynamicPageContainer";
import DynamicPageLogotype from "./Items/Logotype/DynamicPageLogotype";
import DynamicPageCols from "./Items/Grid/DynamicPageCols";
import DynamicPageImage from "./Items/Image/DynamicPageImage";
import DynamicPageVideo from "./Items/Video/DynamicPageVideo";
import DynamicPageList from "./Items/List/DynamicPageList";
import DynamicPageColorBox from "./Items/ColorBox/DynamicPageColorBox";
import DynamicButton from "./Items/Button/DynamicButton";
import DynamicDownloadAppButton from "./Items/DownloadApp/DynamicDownloadAppButton";
import DynamicPageFaqList from "./Items/Faq/DynamicPageFaqList";
import DynamicPageFaqListItem from "./Items/Faq/DynamicPageFaqListItem";
import DynamicPageDownloadAppWithRatingButton from "./Items/DownloadApp/DynamicPageDownloadAppWithRatingButton";
import DynamicPageFooter from "./Items/Footer/DynamicPageFooter";

interface Props {
    item: DynamicPageItemScheme;
}

const DynamicPageItem: React.FC<Props> = ({item}) => {
    switch (item.type) {
        case PageItemType.TITLE:
            return <DynamicPageTitle item={item}/>
        case PageItemType.LOGOTYPE:
            return <DynamicPageLogotype item={item}/>
        case PageItemType.TEXT:
            return <DynamicPageText item={item}/>
        case PageItemType.CONTAINER:
            return <DynamicPageContainer item={item}/>
        case PageItemType.COLS:
            return <DynamicPageCols item={item}/>
        case PageItemType.IMAGE:
            return <DynamicPageImage item={item}/>
        case PageItemType.VIDEO:
            return <DynamicPageVideo item={item}/>
        case PageItemType.LIST:
            return <DynamicPageList item={item}/>
        case PageItemType.COLOR_BOX:
            return <DynamicPageColorBox item={item}/>
        case PageItemType.BUTTON:
            return <DynamicButton item={item}/>
        case PageItemType.DOWNLOAD_APP_BUTTON:
            return <DynamicDownloadAppButton item={item}/>
        case PageItemType.DOWNLOAD_APP_WITH_RATING:
            return <DynamicPageDownloadAppWithRatingButton item={item}/>
        case PageItemType.FAQ_LIST:
            return <DynamicPageFaqList item={item}/>
        case PageItemType.FAQ_LIST_ITEM:
            return <DynamicPageFaqListItem item={item}/>
        case PageItemType.FOOTER:
            return <DynamicPageFooter item={item}/>
        default:
            return <></>
    }
}

export default DynamicPageItem;