export enum PageItemType {
    TITLE = "TITLE",
    TEXT = "TEXT",
    LIST = "LIST",
    LIST_ITEM = "LIST_ITEM",
    CONTAINER = "CONTAINER",
    VIDEO = "VIDEO",
    IMAGE = "IMAGE",
    DOWNLOAD_APP_BUTTON = "DOWNLOAD_APP_BUTTON",
    DOWNLOAD_APP_WITH_RATING = "DOWNLOAD_APP_WITH_RATING",
    COLOR_BOX = "COLOR_BOX",
    FAQ_LIST = "FAQ_LIST",
    FAQ_LIST_ITEM = "FAQ_LIST_ITEM",
    FOOTER = "FOOTER",
    COLS = "COLS",
    LOGOTYPE = "LOGOTYPE",
    BUTTON = "BUTTON"
}