import React, {useState} from "react";
import {DynamicPageItemDownloadAppButtonScheme} from "../../../../types/page.interface";
import Button from "../../../Base/Button/Button";
import styles from "./DynamicDownloadAppButton.module.css";
import PromoCode from "../../../Base/PromoCode/PromoCode";
import Modal from "../../../Base/Modal/Modal";

interface Props {
    item: DynamicPageItemDownloadAppButtonScheme;
}

const DynamicDownloadAppButton: React.FC<Props> = ({item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className={styles.wrapper}>
                {item.forceModal ? <Button title={item.title} icon={item.icon} onClick={() => setIsModalOpen(true)}/> :
                    <Button title={item.title} href={item.href} icon={item.icon}/>}
                {item.showCode && <PromoCode title='Získejte při aktivaci předplatného <b>první měsíc zdarma</b>.' code={item.code}/>}
                {item.showStores && (
                    <div className={styles.stores}>
                        <img src="/images/app-store.webp" alt="AppStore"/>
                        <img src="/images/google-play.webp" alt="Google Play"/>
                    </div>
                )}
            </div>
            {isModalOpen && <Modal title='Máme pro vás dárek' titleIcon='/icons/present.svg' onClose={() => setIsModalOpen(false)}>
                <p>
                    Při registraci použijte kód <span>{item.code}</span> a získejte při aktivaci předplatného první měsíc zdarma.
                </p>
                <br/>
                <p>
                    Registrace zabere jen chvilku, tak stahujte aplikaci
                    a vyzkoušejte lékařskou péči 21. století!
                </p>
                <div className={styles.buttonsWrapper}>
                    <Button title={item.title} href={item.href}/>
                    <PromoCode title='Při registraci použijte kód' code={item.code} dark/>
                </div>
            </Modal>}
        </>
    )
}

export default DynamicDownloadAppButton;