import React from "react";

interface Props{
    children: string | TrustedHTML;
}

const NoEscape: React.FC<Props> = ({children}) => {
    return <div dangerouslySetInnerHTML={{ __html: children }} />
}

export default NoEscape;