import React from "react";
import {DynamicPageItemScheme} from "../../types/page.interface";
import DynamicPageItem from "./DynamicPageItem";

interface Props {
    pageScheme: DynamicPageItemScheme[];
}

const DynamicPage: React.FC<Props> = ({pageScheme}) => {
    return (
        <>
            {pageScheme.map(item => {
                return <DynamicPageItem item={item}/>
            })}
        </>
    )
}

export default DynamicPage;