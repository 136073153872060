import React from "react";
import {DynamicPageItemContainerScheme} from "../../../../types/page.interface";
import styles from './DynamicContainer.module.css';
import DynamicPageItem from "../../DynamicPageItem";

interface Props {
    item: DynamicPageItemContainerScheme;
}

const DynamicPageContainer: React.FC<Props> = ({item}) => {
    const style: React.CSSProperties = {gap: item.gap};

    if (item.center) {
        style.alignItems = "center";
        style.textAlign = "center";
    }

    return (
        <div className={styles.wrapper} style={style}>
            {item.children.map((child) => <DynamicPageItem item={child}/>)}
        </div>
    )
}

export default DynamicPageContainer;