import React from "react";
import Button from "../../../Base/Button/Button";
import {DynamicPageItemButtonScheme} from "../../../../types/page.interface";

interface Props {
    item: DynamicPageItemButtonScheme;
}

const DynamicButton: React.FC<Props> = ({item}) => {
    return <Button title={item.title} href={item.href}/>
}

export default DynamicButton;