import React, {useMemo} from "react";
import {DynamicPageItemListItemScheme} from "../../../../types/page.interface";
import Text from "../../../Base/Text";
import styles from "./DynamicPageListItem.module.css";

interface Props {
    item: DynamicPageItemListItemScheme;
    icon: string;
    theme?: 'LIGHT' | 'DARK';
}

const DynamicPageListItem: React.FC<Props> = ({item, icon, theme = 'LIGHT'}) => {

    const themStyle = useMemo(() => {
        switch (theme) {
            case "DARK":
                return styles.dark;
            default:
            case "LIGHT":
                return styles.light;
        }
    }, [item]);

    return (
        <div className={[styles.wrapper, themStyle].join(" ")}>
            <img src={icon} alt='check'/>
            <Text tx={item.text} noEscape/>
        </div>
    )
}

export default DynamicPageListItem;