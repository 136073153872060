import React, {useState} from "react";
import {DynamicPageItemVideoScheme} from "../../../../types/page.interface";
import Modal from "../../../Base/Modal/Modal";

interface Props {
    item: DynamicPageItemVideoScheme;
}

const DynamicPageVideo: React.FC<Props> = ({item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return <>
        <img src={item.placeholderSrc} alt="video placeholder" onClick={() => setIsModalOpen(true)} style={{cursor: "pointer"}}/>
        {isModalOpen && (
            <Modal onClose={() => setIsModalOpen(false)} fullScreen>
                <iframe style={{aspectRatio: "16/9"}}
                        src={item.src} width="100%" height="auto"
                        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
            </Modal>
        )}
    </>
}

export default DynamicPageVideo;