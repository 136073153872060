import React, { useMemo } from "react";
import Text from "../../../Base/Text";
import styles from "./DynamicPageText.module.css";
import {DynamicPageItemTextScheme} from "../../../../types/page.interface";

interface Props {
    item: DynamicPageItemTextScheme;
}

const DynamicPageText: React.FC<Props> = ({item}) => {

    const style: React.CSSProperties = {};

    if (item.fontSize) {
        style.fontSize = item.fontSize;
    }

    const theme = useMemo(() => {
        switch (item?.theme) {
            case "DARK":
                return styles.dark;
            default:
            case "LIGHT":
                return styles.light;
        }
    }, [item]);

    if (item.isHidden) {
        return <></>
    }

    return <Text tx={item.text} className={[styles.wrapper, theme].join(" ")} style={style} noEscape/>
}

export default DynamicPageText;