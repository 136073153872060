import React from 'react';
import {DynamicPageItemFooterItemScheme} from "../../../../types/page.interface";
import styles from "./DynamicPageFooter.module.css";
import NoEscape from "../../../Base/NoEscape";
import Logotype from "../../../Layout/Logotype/Logotype";
import Text from "../../../Base/Text";

interface Props {
    item: DynamicPageItemFooterItemScheme;
}

const DynamicPageFooter: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <Logotype claim={item.logotypeClaim} dark/>
            </div>
            <div className={styles.center}>
                <img src='/images/iso.webp' alt='iso certification'/>
            </div>
            <Text className={styles.right} tx={item.textRight} noEscape/>
        </div>
    )
}

export default DynamicPageFooter;