import React, { useMemo } from "react";
import {DynamicPageItemListScheme} from "../../../../types/page.interface";
import DynamicPageListItem from "./DynamicPageListItem";
import styles from "./DynamicPageList.module.css";

interface Props {
    item: DynamicPageItemListScheme;
}

const DynamicPageList: React.FC<Props> = ({item}) => {

    const icon = useMemo(() => {
        switch (item?.bulletColor) {
            case "BLUE":
                return '/icons/blue-check.svg';
            default:
            case "GREEN":
                return '/icons/green-check.svg';
        }
    }, [item])

    return (
        <div className={styles.wrapper}>
            {item.children.map((child) => <DynamicPageListItem item={child} icon={icon} theme={item?.theme}/>)}
        </div>
    );
}

export default DynamicPageList;