import React, {useState} from "react";
import styles from "./CookiesNotification.module.css";
import CookiesSettingsItem from "./CookiesSettingsItem";
import Button from "../Button/Button";

interface Props {
    onSuccess: (values?: Record<string, boolean>) => void;
}

const cookiesTypes = [
    {
        name: "Funkční cookies",
        key: 'functionCookies',
        description: "Tyto soubory cookie jsou nezbytné pro fungování webových stránek, není tedy možné je zakázat. Obvykle se nastavují v reakci na akci, kterou na webu sami provedete, jako je nastavení zabezpečení, přihlášení a vyplňování formulářů. Svůj prohlížeč můžete nastavit tak, aby blokoval soubory cookie nebo o nich zasílal upozornění. Mějte na paměti, že některé stránky nebudou bez těchto souborů fungovat. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě. Tyto soubory cookie můžeme nastavit my nebo poskytovatelé třetích stran, jejichž služby na webu využíváme. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě.",
        alwaysOn: true,
    },
    {
        name: "Analytické cookies",
        key: 'analyticsCookies',
        description: "Tyto soubory cookie se používají ke zlepšení fungování webových stránek. Umožňují nám rozpoznat a sledovat počet návštěvníků a sledovat, jak návštěvníci web používají. Pomáhají nám zlepšovat způsob, jakým webové stránky fungují, například tím, že uživatelům umožňují snadno najít to, co hledají. Tyto soubory cookie neshromažďují informace, které by vás mohly identifikovat. Pomocí těchto nástrojů analyzujeme a pravidelně zlepšujeme funkčnost našich webových stránek. Získané statistiky můžeme využít ke zlepšení uživatelského komfortu a k tomu, aby byla návštěva webu pro vás jako uživatele zajímavější. Tyto analytické soubory cookie budou použity pouze s vaším souhlasem.",
    },
    {
        name: "Marketingové cookies",
        key: 'marketingCookies',
        description: "Používají se ke sledování preferencí webu uživatele za účelem cílení reklamy, tj. zobrazování marketingových a reklamních sdělení (i na stránkách třetích stran), které mohou návštěvníka webu zajímat, v souladu s těmito preferencemi. Marketingové cookies využívají nástroje externích společností. Tyto marketingové soubory cookie budou použity pouze s vaším souhlasem.",
    }
]

const CookiesSettings: React.FC<Props> = ({onSuccess}) => {
    const [tempSettings, setTempSettings] = useState<Record<string, boolean>>({
        functionCookies: true,
        analyticsCookies: true,
        marketingCookies: true
    });

    const switchSettings = (key: string) => {
        setTempSettings(prevSettings => ({
            ...prevSettings,
            [key]: !prevSettings[key]
        }));
    };

    return (
        <div className={styles.settings}>
            {cookiesTypes.map((item, key) => (
                <CookiesSettingsItem item={item} onSwitch={switchSettings} isChecked={tempSettings[item.key]}/>
            ))}
            <div>
                <div className={"flex flex-col sm:flex-row mt-3 gap-4"}>
                    <Button title="Přijmout všechny" style={{padding: "12px 16px", textTransform: "uppercase"}}
                            onClick={() => onSuccess()}/>
                    <Button title="Uložit" theme='blueOutline'
                            style={{padding: "12px 16px", textTransform: "uppercase"}}
                            onClick={() => onSuccess(tempSettings)}/>
                </div>
            </div>
        </div>
    )
}

export default CookiesSettings;