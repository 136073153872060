import React, {ReactElement, useMemo} from "react";
import styles from "./Button.module.css";

interface Props {
    title?: string;
    onClick?: () => void;
    href?: string;
    icon?: string;
    theme?: 'blueOutline';
    style?: React.CSSProperties;
}

const Button: React.FC<Props> = ({title, onClick, href, icon, theme, style}) => {

    const className = useMemo(() => {
        const clases = [styles.wrapper];

        if (theme === 'blueOutline') {
            clases.push(styles.blueOutline);
        }

        return clases.join(" ");
    }, [theme])

    let content: string | undefined | ReactElement = title;

    if (icon) {
        content = <img src={icon} alt='icon'/>
    }

    if (href) {
        return <a className={className} href={href} style={style}>{content}</a>
    }

    return <div className={className} onClick={onClick} style={style}>{content}</div>
}

export default Button;