import React, {useState} from "react";
import styles from "./CookiesNotification.module.css";
import Switch from "react-switch";

interface Props {
    item: {
        name: string;
        key: string;
        alwaysOn?: boolean;
        description: string;
    }
    onSwitch: (key: string) => void;
    isChecked: boolean;
}

const CookiesSettingsItem: React.FC<Props> = ({item, onSwitch, isChecked}) => {
    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

    return (
        <div className={styles.settingItem}>
            <div className={styles.header}>
                <div onClick={() => setIsDescriptionOpen(!isDescriptionOpen)} className={styles.label}><img src="/icons/chevron-down-solid.svg" alt="arrow-down"/> {item.name}</div>
                <Switch checked={isChecked} disabled={item.alwaysOn} checkedIcon={false}
                        uncheckedIcon={false}
                        onColor="#3e5470"
                        onChange={() => onSwitch(item.key)}/>
            </div>
            {isDescriptionOpen && (<div>{item.description}</div>)}
        </div>
    )
}

export default CookiesSettingsItem;