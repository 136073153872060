import React, {useMemo} from "react";
import styles from "./DynamicPageTitle.module.css";
import {DynamicPageItemTitleScheme} from "../../../../types/page.interface";
import NoEscape from "../../../Base/NoEscape";

interface Props {
    item: DynamicPageItemTitleScheme;
}

const DynamicPageTitle: React.FC<Props> = ({item}) => {

    const style: React.CSSProperties = {};

    if (item.fontSize) {
        style.fontSize = item.fontSize;
    }

    const theme = useMemo(() => {
        switch (item?.theme) {
            case "DARK":
                return styles.dark;
            default:
            case "LIGHT":
                return styles.light;
        }
    }, [item]);

    const output = <NoEscape>{item.title}</NoEscape>

    switch (item.subType) {
        case "H1":
            return <h1 className={[styles.h1, theme].join(" ")} style={style}>{output}</h1>
        case "H2":
            return <h2 className={[styles.h2, theme].join(" ")} style={style}>{output}</h2>;
        case "H3":
            return <h3 className={[styles.h3, theme].join(" ")} style={style}>{output}</h3>;
        case "H4":
            return <h4 className={[styles.h4, theme].join(" ")} style={style}>{output}</h4>
        default:
            return <></>
    }
}

export default DynamicPageTitle;