import React, {useMemo} from "react";
import {DynamicPageItemColorBoxScheme} from "../../../../types/page.interface";
import DynamicPageItem from "../../DynamicPageItem";
import styles from "./DynamicPageColorBox.module.css";

interface Props {
    item: DynamicPageItemColorBoxScheme;
}

const DynamicPageColorBox: React.FC<Props> = ({item}) => {

    const theme = useMemo(() => {
        switch (item?.theme) {
            case "BLUE":
                return styles.blue;
            default:
            case "PURPLE":
                return styles.purple;
        }
    }, [item]);

    return <div className={[styles.wrapper, theme].join(" ")}>{item.children.map((child) => <DynamicPageItem item={child}/>)}</div>
}

export default DynamicPageColorBox;