import React from "react";
import styles from "./Modal.module.css";

interface Props {
    title?: string;
    titleIcon?: string;
    children: React.ReactElement | React.ReactElement[];
    onClose: () => void;
    fullScreen?: boolean;
}

const Modal: React.FC<Props> = ({title, titleIcon, children, onClose, fullScreen}) => {
    return (
        <div className={styles.backdrop}>
            <div className={[styles.modal, fullScreen ? styles.fullScreen : ''].join(" ")}>
                <div className={styles.close} onClick={onClose}><img src="/icons/xmark-solid.svg" alt='xmark'/></div>
                {title && (
                    <div className={styles.title}>
                        {titleIcon && <img src={titleIcon} alt='icon'/>} {title}
                    </div>
                )}
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;