import React, { useState } from "react";
import styles from './CookiesNotification.module.css';
import {useCookies} from "react-cookie";
import CookiesSettings from "./CookiesSettings";
import Button from "../Button/Button";

interface Props {

}

const CookiesNotification: React.FC<Props> = () => {
    const [isSettingOpen, setIsSettingOpen] = useState(false);
    const [cookies, setCookie] = useCookies(["cookieConsentSettings"]);

    const saveCookieSettings = (values?: Record<string, boolean>) => {
        const settings: Record<string, boolean> = values ?? {
            functionCookies: true,
            analyticsCookies: true,
            marketingCookies: true,
        };
        setCookie("cookieConsentSettings", settings, {path: "/"});

        // @ts-ignore
        gtag('consent', 'update', {
            'ad_storage': settings.marketingCookies ? 'granted' : 'denied',
            'analytics_storage': settings.analyticsCookies ? 'granted' : 'denied'
        });
        if (settings.marketingCookies) {
            // @ts-ignore
            gtag('event', 'ad_storage');
        }
        if (settings.analyticsCookies) {
            // @ts-ignore
            gtag('event', 'analytics_storage');
        }
    };

   return (
       <div className={styles.backdrop}>
           <div className={styles.cookiesNotification}>
               <div className={styles.scrollContent}>
                   <div className={styles.title}>Cookies</div>
                   <div>Na našich webových stránkách používáme cookies, abychom zlepšili váš zážitek z jejich používání. Rádi bychom vás však ujistili, že <b>v aplikaci MEDDI app nesbíráme žádná data</b> a ani nepoužíváme žádné cookies. Vaše soukromí a bezpečí jsou pro nás na prvním místě.</div>
                   {!isSettingOpen ? (
                       <>
                           <div className={"flex flex-col sm:flex-row  mt-3 gap-4"}>
                               <Button title="Přijmout všechny" style={{padding: "12px 16px", textTransform: "uppercase"}} onClick={() => saveCookieSettings()}/>
                               <Button title="Nastavení" theme='blueOutline' style={{padding: "12px 16px", textTransform: "uppercase"}} onClick={() => setIsSettingOpen(true)}/>
                           </div>
                       </>
                   ) : (
                       <CookiesSettings onSuccess={saveCookieSettings}/>
                   )}
               </div>
           </div>
       </div>
   )
}

export default CookiesNotification;