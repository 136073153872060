import React from "react";
import DynamicPageItem from "../../DynamicPageItem";
import {DynamicPageItemColsScheme} from "../../../../types/page.interface";
import styles from "./DynamicPageCols.module.css";

interface Props {
    item: DynamicPageItemColsScheme;
}

const DynamicPageCols: React.FC<Props> = ({item}) => {
    const style: React.CSSProperties = {gap: item.gap};

    if (item.center) {
        style.alignItems = "center";
    }

    return (
        <div className={styles.wrapper} style={style}>
            {item.children.map((child) => {
                if (!child.isHidden) {
                    return <div className={styles.col}><DynamicPageItem item={child}/></div>
                }
            })}
        </div>
    )
}

export default DynamicPageCols;