import {DynamicPageItemScheme} from "../types/page.interface";
import {PageItemType} from "../enums/pageItemType.enum";
import { isMobile } from "react-device-detect";

export const FOR_FAMILY: DynamicPageItemScheme[] = [
    {
        type: PageItemType.CONTAINER,
        gap: isMobile ? 60 : 100,
        children: [
            {
                type: PageItemType.CONTAINER,
                gap: isMobile ? 40 : 100,
                children: [
                    {
                        type: PageItemType.LOGOTYPE,
                        claim: 'Ordinace bez hranic',
                        showDownloadAppButton: isMobile
                    },
                    {
                        type: PageItemType.COLS,
                        gap: isMobile ? 40 : 160,
                        center: true,
                        children: [
                            {
                                type: PageItemType.IMAGE,
                                src: "https://cdn.meddi.com/hello/image1.png",
                                isHidden: !isMobile
                            },
                            {
                                type: PageItemType.CONTAINER,
                                gap: 30,
                                children: [
                                    {
                                        type: PageItemType.TITLE,
                                        title: 'O aplikaci MEDDI',
                                        subType: "H1",
                                    },
                                    {
                                        type: PageItemType.TEXT,
                                        text: 'Díky aplikaci MEDDI <span class="bold">můžete být ve spojení s lékařem kdykoliv a odkudkoliv.</span> Zdravotní komplikace – ať už vaše nebo vašich blízkých, můžete konzultovat prostřednictvím zpráv i videohovoru. A pokud to lékař uzná za vhodné, recept budete mít do několika minut. <span class="bold">Vše vyřešíte online, bez zbytečného čekání a z pohodlí domova.</span>',
                                        isHidden: isMobile
                                    },
                                    {
                                        type: PageItemType.TEXT,
                                        text: 'Buďte <span class="bold">ve spojení s lékařem kdykoli a odkudkoli.</span> Zdraví celé rodiny můžete s lékařem řešit pomocí zpráv i videohovoru a recept můžete mít <span class="bold">do pár minut.</span> Vše online, rychle a bez čekání.',
                                        isHidden: !isMobile
                                    },
                                    {
                                        type: PageItemType.DOWNLOAD_APP_BUTTON,
                                        title: "Stáhnout aplikaci ZDARMA",
                                        href: "https://onelink.to/meddiapp",
                                        code: "LETO1",
                                        showStores: true,
                                        showCode: false,
                                        forceModal: true
                                    }
                                ]
                            },
                            {
                                type: PageItemType.IMAGE,
                                src: "https://cdn.meddi.com/hello/image1.png",
                                isHidden: isMobile
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.COLS,
                center: true,
                gap: 45,
                children: [
                    {
                        type: PageItemType.IMAGE,
                        src: isMobile ? "https://cdn.meddi.com/hello/image6.webp" : "https://cdn.meddi.com/hello/image2.webp"
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 30,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Proč se spolehnout na aplikaci MEDDI?',
                                subType: "H2"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: '<span class="bold">Od pediatrů přes praktické lékaře až po specialisty. S MEDDI máte prvotřídní lékařskou péči stále po ruce.</span> Ušetřete si čas strávený v čekárně. Část zdravotních potíží vyřešíte pohodlně a online. Přes počítač nebo z mobilu. Záleží jen na vás. '
                            },
                            {
                                type: PageItemType.LIST,
                                bulletColor: 'GREEN',
                                children: [
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Dostupnost lékařské péče 24/7'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Možnost řešit <span class="bold">zdraví celé rodiny</span> na jednom místě'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Méně návštěv ordinací plných bakterií a virů'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Už žádný čas strávený v čekárnách'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: '<span class="bold">Neomezený počet</span> vyšetření a konzultací s ambulantním specialistou'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Možnost řešit i duševní zdraví'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: '1x měsíčně měření <span class="underline">MEDDI BioScan</span> zdarma'
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.CONTAINER,
                gap: 30,
                center: true,
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 15,
                        center: true,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Vyšetření z pohodlí domova',
                                subType: "H4",
                            },
                            {
                                type: PageItemType.TITLE,
                                title: 'Tisíce lékařů pro vaši rodinu stále po ruce',
                                subType: "H2"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: '<span class="bold">Zapomeňte na přeplněné čekárny.</span> Velkou část návštěv lékaře lze snadno</br> nahradit online spojením. Podívejte se, jak naše aplikace funguje.'
                            }
                        ]
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 0,
                        center: true,
                        children: [
                            {
                                type: PageItemType.VIDEO,
                                src: 'https://player.vimeo.com/video/710363844?h=b600654096&autoplay=1',
                                placeholderSrc: 'https://cdn.meddi.com/hello/image3.webp'
                            },
                            {
                                type: PageItemType.DOWNLOAD_APP_WITH_RATING,
                                code: "LETO1",
                                title: "Stáhnout aplikaci ZDARMA",
                                textLeft: 'Více než <span class="bold">200 000 stažení</span>',
                                textRight: '<span>95%</span> pozitivních hodnocení',
                                href: "https://onelink.to/meddiapp",
                                forceModal: true,
                            }
                        ]
                    }
                ]
            },
            {
                type: PageItemType.COLS,
                gap: isMobile ? 60 : 160,
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 30,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Jak aplikace funguje ',
                                subType: "H3",
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: 'MEDDI vám poskytuje širokou škálu možností, jak se s lékařem spojit a jak mu poskytnout informace. <span class="bold">Díky tomu snadno vyřešíte celou řadu zdravotních potíží – ať už vašich nebo vaší rodiny.</span>',
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.LIST,
                                bulletColor: "BLUE",
                                theme: "DARK",
                                children: [
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "V široké databázi lékařů <span class='bold'>rychle najdete specialistu</span> na to, co vás zrovna trápí"
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "K popisu problémů snadno připojíte fotografie nebo se s vámi lékař spojí videohovorem"
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "Potvrzení a recepty vám lékař vystaví jednoduše online"
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "Nadstandardní hrazené úkony zaplatíte na několik kliknutí skrze zabezpečenou platební bránu"
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 30,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Kolik aplikace stojí?',
                                subType: "H3",
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: 'Prostřednictvím MEDDI app se můžete s lékařem spojit jedenkrát za rok zdarma, poté je aplikace zpoplatněna dle aktuálního ceníku. Využít můžete <span class="bold">výhodné předplatné pro vás, nebo celou vaši rodinu.</span>',
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.IMAGE,
                                src: "https://cdn.meddi.com/hello/image7.webp"
                            }
                        ]
                    }
                ]
            },
            {
                type: PageItemType.COLOR_BOX,
                backgroundImageSrc: "",
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 40,
                        center: true,
                        children: [
                            {
                                type: PageItemType.CONTAINER,
                                gap: 15,
                                center: true,
                                children: [
                                    {
                                        type: PageItemType.TITLE,
                                        title: 'Vyzkoušejte MEDDI',
                                        subType: "H2",
                                    },
                                    {
                                        type: PageItemType.TEXT,
                                        text: 'Nainstalovat a nastavit aplikaci vám nezabere více než pár minut.<br/> <span class="bold">Neztrácejte čas v čekárnách a věnujte se naplno rodině.</span>'
                                    },
                                ]
                            },
                            {
                                type: PageItemType.DOWNLOAD_APP_BUTTON,
                                title: "Stáhnout aplikaci ZDARMA",
                                href: "https://onelink.to/meddiapp",
                                code: "LETO1",
                                forceModal: true
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.CONTAINER,
                gap: 40,
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 0,
                        center: true,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                subType: "H2",
                                title: "Často kladené dotazy k aplikaci MEDDI app",
                                theme: "DARK"
                            }
                        ]
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 0,
                        children: [
                            {
                                type: PageItemType.FAQ_LIST,
                                children: [
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Jak mám přidat ke svému účtu moje dítě?",
                                        text: 'Přidání dítěte provedete na kartě "Můj profil" prostřednictvím volby "Rodina", kde naleznete přehled dětí a možnost přidat další. I u dítěte je nutné vložit všechny požadované údaje dle skutečných informací. Jen tak může lékař při použití telemedicínské aplikace správně diagnostikovat obtíže.'
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Proc mám vyplnit rodné číslo?",
                                        text: "Rodné číslo slouží k Vaší identifikaci v systému zdravotního pojišťění, lékového záznamu a k vystaveni eReceptu či lékařské zprávy."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Proč mám vyplnit zdravotní kartu?",
                                        text: "Vyplnit zdravotní kartu není povinné."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Jak dlouho trvá vyřízení mého požadavku?",
                                        text: "Záleží to na jeho časové vytíženosti a prioritách jednotlivých případů, podobně jako v ordinaci. O tom, že požadujete od lékaře péči, je lékař vždy aplikací upozorněn. Průměrná doba čekání na spojení s lékařem v roce 2021 byla 6 minut.."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Jak se dozvím, že byl můj požadavatek zpracován a doručen lékaři?",
                                        text: "Po zadání požadavku Vás aplikace informuje jakmile Vás lékař přijme do virtuální čekárny."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Je možné využívat služby MEDDI app bez registrace?",
                                        text: "Bez kompletně vyplněné registrace není možné zajistit plnohodnotnou funkčnost všech služeb."
                                    },
                                ]
                            },
                            {
                                type: PageItemType.DOWNLOAD_APP_WITH_RATING,
                                code: "LETO1",
                                title: "Stáhnout aplikaci ZDARMA",
                                textLeft: 'Více než <span class="bold">200 000 stažení</span>',
                                textRight: '<span>95%</span> pozitivních hodnocení',
                                href: "https://onelink.to/meddiapp",
                                forceModal: true,
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.FOOTER,
                logotypeClaim: 'Ordinace bez hranic',
                textRight: 'Více informací najdete na <br/><a href="https://www.meddi.com" target="_blank">www.meddi.com</a>'
            }
        ]
    }
]