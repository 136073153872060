import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ForFamily from "./containers/ForFamily";
import ForManagers from "./containers/ForManagers";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
    {
        path: "/",
        element: <ForFamily/>
    },
    {
        path: "/for-family",
        element: <ForFamily/>
    },
    {
        path: "/for-managers",
        element: <ForManagers/>
    },
]);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

reportWebVitals();
