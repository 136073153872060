import React from "react";
import {DynamicPageItemLogotypeScheme} from "../../../../types/page.interface";
import Logotype from "../../../Layout/Logotype/Logotype";

interface Props {

    item: DynamicPageItemLogotypeScheme;
}

const DynamicPageLogotype: React.FC<Props> = ({item}) => (<Logotype claim={item.claim} showDownloadAppButton={item.showDownloadAppButton}/>);

export default DynamicPageLogotype;